<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
  <div>
    <button @click="logAdd">Показать</button>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  methods:{
   logAdd(){
    const  apiUrl = process.env.VUE_APP_API_URL;
    console.log(apiUrl)
   } 
  },
  components: {
    HelloWorld
  }
}
</script>
